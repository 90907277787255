import Vue from 'vue'
import App from './App.vue'
import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faArrowUp,
  faArrowDown,
  faMinus,
  faPlus,
  faFire,
  faCog,
  faTrash,
  faVideo,
  faCheck,
  faTimes, faArrowRight, faArrowsAltH
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import axios from 'axios'
import router from './router'

library.add(faArrowUp, faArrowDown, faPlus, faMinus, faFire, faCog, faTrash, faVideo, faCheck, faTimes, faArrowRight, faArrowsAltH)


Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.prototype.$http = axios
Vue.prototype.$apiUrl = process.env.VUE_APP_API_URL
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
