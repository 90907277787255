<template>
  <div class="col-6">
    <fieldset class="border border-3 p-2 h-100">
      <legend class="float-none w-auto px-3 text-start">Info</legend>
      <div class="row">
        <div class="col-4 text-end" style="line-height: 3rem">
          <span>čas</span>
        </div>
        <div class="col-7 offset-1 text-start" style="line-height: 3rem">
          <span style="font-size: 3rem">{{ time }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-4 text-end" style="line-height: 3rem">
          <span>datum</span>
        </div>
        <div class="col-7 offset-1 text-start" style="line-height: 3rem">
          <span style="font-size: 2rem">{{ date }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-4 text-end" style="line-height: 3rem">
          <span>tlak [hPa]</span>
        </div>
        <div class="col-7 offset-1 text-start" style="line-height: 3rem">
          <span style="font-size: 2rem">{{ pressure }}</span>
        </div>
      </div>
      <div class="row pb-1">
        <div class="col-4 text-end" style="line-height: 2rem">
          <span>vlhkost [%]</span>
        </div>
        <div class="col-7 offset-1 text-start" style="line-height: 2rem">
          <span style="font-size: 2rem">{{ humidity }}</span>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
export default {
  name: "Info",
  data() {
    return {
      fetchInterval: null,
      interval: null,
      time: null,
      date: null,
      pressure: 0,
      humidity: 0
    }
  }, beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    fetchData() {
      this.$http.get(this.$apiUrl + "/weather").then(response => {
        this.pressure = response.data.pressure
        this.humidity = response.data.humidity
      })
    }
  },
  created() {
    this.interval = setInterval(() => {
      this.time = Intl.DateTimeFormat(navigator.language, {
        hour: 'numeric',
        minute: 'numeric'
      }).format()
      this.date = Intl.DateTimeFormat(navigator.language, {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      }).format()
    }, 1000)
  },
  mounted() {
    this.fetchData()
    this.fetchInterval = setInterval(this.fetchData, 60000 * 30)
  }
}
</script>

<style scoped>

</style>