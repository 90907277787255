<template>
  <div class="col-6">
    <fieldset class="border border-3 p-2 h-100">
      <legend class="float-none w-auto px-3 text-start">FanCoil</legend>
      <div class="row">
        <div class="col-4 text-end">
          <span style="font-size: 3rem">22<sup class="small-c">°C</sup></span>
        </div>
        <div class="col-8 text-center">
          <button class="btn btn-outline-light btn-lg" style="font-size: 2rem">
            On
          </button>
          &nbsp;
          <button class="btn btn-outline-light btn-lg" style="font-size: 2rem; color: red; border: red solid 1px">
            Off
          </button>
          &nbsp;
          <button class="btn btn-outline-light btn-lg" style="font-size: 2rem">
            A
          </button>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
export default {
  name: "Fancoil",
  data() {
    return {
      temp: 0,
      direction: "stale",
      fetchInterval: null,
      error: false
    }
  },
  methods: {
    fetchTemp() {
      this.$http.get(this.$apiUrl + "/room/d238c014-05aa-4e6a-9aea-7371fb81f00e/temperature")
          .then(response => {
            this.temp = response.data.temperature
            this.error = response.data.error
            this.direction = response.data.direction
          })
    }
  },
  mounted() {
    this.fetchTemp()
    this.fetchInterval = setInterval(this.fetchTemp, 60000)
  }
}
</script>

<style scoped>

</style>