<template>
  <div class="container-fluid">
    <div id="app">
      <div class="row mt-2">
        <VenkovniTeplota/>
      </div>
      <div class="row mt-3">
        <JirikTeplota/>
        <LozniceTeplota/>
      </div>
      <div class="row mt-3">
        <JirkaTeplota/>
        <PivniceTeplota/>
      </div>
      <div class="row mt-3">
        <Info/>
        <Pocasi/>
      </div>
      <div class="row mt-3">
        <Thermostat/>
      </div>
      <div class="row mt-3">
        <Fancoil/>
        <Nastaveni/>
      </div>
    </div>
  </div>
</template>

<script>
import VenkovniTeplota from "@/components/VenkovniTeplota";
import JirikTeplota from "@/components/JirikTeplota";
import LozniceTeplota from "@/components/LozniceTeplota";
import PivniceTeplota from "@/components/PivniceTeplota";
import JirkaTeplota from "@/components/JirkaTeplota";
import Pocasi from "@/components/Pocasi";
import Info from "@/components/Info";
import Thermostat from "@/components/Thermostat";
import Nastaveni from "@/components/Nastaveni";
import Fancoil from "@/components/Fancoil";

export default {
  name: 'Home',
  components: {
    Fancoil,
    Nastaveni,
    Thermostat,
    Info,
    Pocasi,
    JirkaTeplota,
    PivniceTeplota,
    LozniceTeplota,
    JirikTeplota,
    VenkovniTeplota
  }
}
</script>
<style>

legend {
  width: inherit; /* Or auto */
  padding: 0 10px; /* To give a bit of padding on the left and right */
  border-bottom: none;
}
.small-c {
  font-size: 1.5rem;
}
</style>
