<template>
  <div class="col-6">
    <fieldset class="border border-3 p-2 h-100">
      <legend class="float-none w-auto px-3 text-start">Počasí</legend>
      <div class="row">
        <h6>Aktuální počasí</h6>
      </div>
      <div class="row">
        <div class="col-8">
          <div class="row">
            <div class="col-6 text-end" style="line-height: 1.5rem">
              teplota:
            </div>
            <div class="col-6 text-start" style="font-size: 1.5rem; line-height: 1.5rem">
              {{ temperature }}°C
            </div>
          </div>
          <div class="row">
            <div class="col-6 text-end" style="line-height: 1.5rem">
              teplota pocit.:
            </div>
            <div class="col-6 text-start" style="font-size: 1.5rem; line-height: 1.5rem">
              {{ feelsTemperature }}°C
            </div>
          </div>
          <div class="row">
            <div class="col-6 text-end" style="line-height: 1.5rem">
              rychlost vítr:
            </div>
            <div class="col-6 text-start" style="font-size: 1.5rem; line-height: 1.5rem">
              {{ windKph }} km/h
            </div>
          </div>
        </div>
        <div class="col-4 text-start">
          <img v-if="condition !== null" style="height: 5rem" v-bind:src="condition"/>
        </div>
      </div>
      <div class="row mt-3">
        <h6>&nbsp;&nbsp;&nbsp;&nbsp;<sub>dnes</sub> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Předpověď počasí&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <sub>zitra</sub></h6>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-6">
              <div class="row">
                <div class="col-5 text-end">
                  <img v-if="todayCondition" v-bind:src="todayCondition"/>
                </div>
                <div class="col-7 text-start" style="line-height: 2rem">
                  max: {{ todayMaxTemperature }}°C<br />
                  min: {{ todayMinTemperature }}°C
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-5 text-end">
                  <img v-if="tomorrowCondition" v-bind:src="tomorrowCondition"/>
                </div>
                <div class="col-7 text-start" style="line-height: 2rem">
                  max: {{ tomorrowMaxTemperature }}°C<br />
                  min: {{ tomorrowMinTemperature }}°C
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
export default {
  name: "Pocasi",
  data() {
    return {
      temperature: 0,
      feelsTemperature: 0,
      windKph: 0,
      windDirection: null,
      condition: null,
      pressure: 0,
      todayMaxTemperature: 0,
      todayMinTemperature: 0,
      tomorrowMaxTemperature: 0,
      tomorrowMinTemperature: 0,
      todayCondition: null,
      tomorrowCondition: null,
      fetchInterval: null
    }
  },
  methods: {
    fetchData() {
      this.$http.get(this.$apiUrl + "/weather").then(response => {
        this.temperature = response.data.temperature
        this.feelsTemperature = response.data.feelsLikeTemperature
        this.windKph = response.data.windKph
        this.windDirection = response.data.windDirection
        this.condition = response.data.condition
        this.pressure = response.data.pressure
        this.todayMaxTemperature = response.data.todayMaxTemperature
        this.todayMinTemperature = response.data.todayMinTemperature
        this.tomorrowMaxTemperature = response.data.tomorrowMaxTemperature
        this.tomorrowMinTemperature = response.data.tomorrowMinTemperature
        this.todayCondition = response.data.todayCondition
        this.tomorrowCondition = response.data.tomorrowCondition
      })
    }
  },
  mounted() {
    this.fetchData()
    this.fetchInterval = setInterval(this.fetchData, 60000 * 30)
  }
}
</script>

<style scoped>

</style>