<template>
  <div class="col-12">
    <fieldset class="border border-3 p-2 shadow-sm"
              v-bind:style="[errorSetting || errorTemp ? { 'box-shadow': '0 .125rem 1rem red!important' } : '']">
      <legend class="float-none w-auto px-3 text-start">
        Termostat
      </legend>
      <div class="row">
        <div class="col-1 text-end">
          <br/>
          <span style="font-size: 2rem; line-height: 3rem">
            <font-awesome-icon v-if="enabled" icon="fire" style="color: darkorange"/>
          </span>
        </div>
        <div class="col-2 text-center">
          teplota kotel
          <div class="mt-2">
            <span v-bind:style="[errorSetting ? { 'color': 'red' } : { 'color': 'white' } ]"
                  style="font-size: 2rem; line-height: 3rem">{{ temp }}<sup class="small-c">°C</sup></span></div>
        </div>
        <div class="col-2 text-center">aktuální <br/>
          <span v-bind:style="[errorTemp ? { 'color': 'red' } : { 'color': 'white' } ]" style="font-size: 3rem">{{ obyvak }}<sup
            class="small-c">°C</sup></span>
        </div>
        <div class="col-2 text-center">nastavená <br/> <span style="font-size: 3rem">{{
            temperatureSettings
          }}<sup class="small-c">°C</sup></span>
        </div>
        <div class="col-2 offset-1 text-center">
          <button v-on:click="minusTemp" class="btn btn-outline-light btn-lg" style="font-size: 3rem">
            <font-awesome-icon icon="minus" style="color: dodgerblue"/>
          </button>
        </div>
        <div class="col-2 text-center">
          <button v-on:click="plusTemp" class="btn btn-outline-light btn-lg" style="font-size: 3rem">
            <font-awesome-icon icon="plus" style="color: red"/>
          </button>
        </div>
      </div>
    </fieldset>
  </div>

</template>

<script>
export default {
  name: "Thermostat",
  data() {
    return {
      temp: 0,
      temperatureSettings: 0,
      obyvak: 0,
      enabled: true,
      fetchInterval: null,
      fetchInterval2: null,
      errorSetting: false,
      errorTemp: false
    }
  },
  methods: {
    fetchTemp() {
      this.$http.get(this.$apiUrl + "/room/e3e20303-4469-40d1-bd2a-310c8800e2b1/temperature")
          .then(response => (this.temp = response.data.temperature))
      this.$http.get(this.$apiUrl + "/room/1f7ef535-9374-46a5-a43c-d6ff466b8c89/temperature")
          .then(response => {
            this.obyvak = response.data.temperature
            this.errorTemp = response.data.error
          })
    },
    fetchData() {
      this.$http.get(this.$apiUrl + "/thermostat").then(response => {
        this.temperatureSettings = response.data.temperatureSetting
        this.enabled = response.data.enabled
        this.errorSetting = response.data.error
      })
    },
    plusTemp() {
      this.$http.patch(this.$apiUrl + "/thermostat", {
        direction: "up"
      }).then(response => this.temperatureSettings = response.data.temperatureSetting)
    },
    minusTemp() {
      this.$http.patch(this.$apiUrl + "/thermostat", {
        direction: "down"
      }).then(response => this.temperatureSettings = response.data.temperatureSetting)
    }
  },
  mounted() {
    this.fetchTemp()
    this.fetchData()
    this.fetchInterval = setInterval(this.fetchTemp, 60000)
    this.fetchInterval2 = setInterval(this.fetchData, 90000)
  }
}
</script>

<style scoped>

</style>