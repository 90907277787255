<template>
  <div class="col-6">
    <fieldset class="border border-3 p-2" v-bind:style="[error ? { 'box-shadow': '0 .125rem 1rem red!important' } : '']">
      <legend class="float-none w-auto px-3 text-start">Jiřík</legend>
      <div class="row">
        <div class="col-5 offset-1 text-center">
          <span style="font-size: 3rem">{{ temp }}<sup class="small-c">°C</sup></span>
        </div>
        <div class="col-5 text-center">
                        <span style="font-size: 3rem">
                          <img style="height: 2rem" v-if="direction === 'up'" :src="up" />
                          <img style="height: 2rem" v-if="direction === 'down'" :src="down" />
                          <img style="width: 1.8rem" v-if="direction === 'stale'" :src="stale" />
                        </span>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
export default {
  name: "JirikTeplota",
  data() {
    return {
      temp: 0,
      direction: "stale",
      fetchInterval: null,
      error: false,
      down: require('@/assets/DOWN.svg'),
      up: require('@/assets/UP.svg'),
      stale: require('@/assets/STALE.svg')
    }
  },
  methods: {
    fetchTemp() {
      this.$http.get(this.$apiUrl + "/room/54161a38-976e-4bf2-9d0e-4886138302da/temperature")
          .then(response => {
            this.temp = response.data.temperature
            this.direction = response.data.direction
            this.error = response.data.error
          })
    }
  },
  mounted() {
    this.fetchTemp()
    this.fetchInterval = setInterval(this.fetchTemp, 60000)
  }
}
</script>

<style scoped>

</style>