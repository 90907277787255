<template>
  <div class="col-12">
    <fieldset class="border p-2">
      <legend class="float-none text-start w-auto px-3">
        Místnosti a URL pro arduino (poslání teplot) &nbsp;&nbsp;
        <router-link to="/">
          <button class="btn btn-sm btn-outline-light">
            Zpět na přehled
          </button>
        </router-link>
      </legend>
      <div class="row">
        <div class="col-12">
          <ul class="list-group">
            <li v-for="item in rooms" :key="item.id" class="list-group-item">
              <div class="row">
                <div class="col-3 text-start">
                  {{ item.name }}:
                </div>
                <div class="col-9 text-start">
                  {{ item.url }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
export default {
  name: "BaseSettings",
  data() {
    return {
      rooms: []
    }
  },
  mounted() {
    this.getRooms()
  },
  methods: {
    getRooms() {
      this.$http.get(this.$apiUrl + "/rooms").then(response => this.rooms = response.data)
    }
  }
}
</script>

<style scoped>

</style>