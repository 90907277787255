<template>
  <div class="col-6">
    <fieldset class="border border-3 p-2 h-100">
      <legend class="float-none w-auto px-3 text-start">
        Nastavení
      </legend>
      <div class="row">
        <div class="col-4 text-center">
          <a onclick="this.blur();" href="com.tplink.iot://" class="btn btn-outline-light  h-100" style="font-size: 2rem">
            <font-awesome-icon icon="video" />
          </a>
        </div>
        <div class="col-4 text-center">
          <router-link to="/settings">
            <button class="btn btn-outline-light" style="font-size: 2rem">
              t
              <sup style="">
                <b>
                  <font-awesome-icon icon="cog"/>
                </b>
              </sup>
            </button>
          </router-link>
        </div>
        <div class="col-4" style="font-size: 2rem">
          <router-link to="/base-settings">
            <button class="btn btn-outline-light h-100" style="font-size: 2rem">
              <font-awesome-icon icon="cog"/>
            </button>
          </router-link>
        </div>
      </div>

    </fieldset>
  </div>

</template>

<script>
export default {
  name: "Nastaveni"
}
</script>

<style scoped>

</style>